<template>
  <div v-if="step == 1">
    <div class="container" style="padding:10px;margin-bottom:20px;">
      <el-button type="primary" @click="printPreview">
        <el-icon>
          <document />
        </el-icon>
        Print Preview
      </el-button>
    </div>
    <div class="receiptTemplate">
      <el-descriptions border title="Header" direction="vertical" :column="4">
        <el-descriptions-item>
          <template #label>
            <el-icon>
              <user />
            </el-icon>
            Bill To
          </template>
          {{ form.billTo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <el-icon>
              <calendar />
            </el-icon>
            Bill date
          </template>
          {{ this.$formatter.formatDate(form.billDate) }}
        </el-descriptions-item>
        <el-descriptions-item label="GST Rate(%)">
          {{ form.gst }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <el-icon>
              <discount />
            </el-icon>
            Discount(%)
          </template>
          {{ form.discount }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="receiptTemplate">
      <el-descriptions border title="Discount Items" direction="horizontal">
        <div v-for="item in this.form.discountItems" :key="item.name">
          <el-descriptions-item>
            <template #label>
              <el-icon>
                <calendar />
              </el-icon>
              {{ item.name }}
            </template>
            {{ item.discount }}%
          </el-descriptions-item>
        </div>
      </el-descriptions>
    </div>
    <div class="receiptTemplate">
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">Items</div>
      </div>
      <el-table :data="this.form.items" border class="courseListTable">
        <el-table-column label="Description">
          <template #default="scope">
            {{ scope.row.title }}
            {{ scope.row.term ? `, ${scope.row.term}` : "" }}
            {{ scope.row.comment ? `, ${scope.row.comment}` : "" }}
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="Class Hours" width="120">
        </el-table-column>
        <el-table-column label="Unit Price" width="120">
          <template #default="scope">
            {{
              scope.row.unitPrice
                ? `${this.$formatter.formatDecimal(scope.row.unitPrice)}`
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="Subtotal (Incl.GST)" width="160">
          <template #default="scope">
            {{
              this.$formatter.formatDecimal(this.calculateItemTotal(scope.row))
            }}
          </template>
        </el-table-column>
        <el-table-column label="GST" width="120">
          <template #default="scope">
            {{
              this.$formatter.formatDecimal(this.calculateItemGST(scope.row))
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="receiptTemplate">
      <el-descriptions border title="Summary" :column="1">
        <el-descriptions-item label="Total Subtotal">
          ${{ this.$formatter.formatDecimal(this.calculateNetTotal()) }}
        </el-descriptions-item>
        <el-descriptions-item label="Discount">
          ${{ this.$formatter.formatDecimal(this.calculateDiscountAmount()) }}
        </el-descriptions-item>
        <el-descriptions-item label="TOTAL (Round Up)">
          ${{ this.$formatter.formatDecimal(this.calculateTotal()) }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
  <div v-else class="printReviewStep">
    <div class="container" style="padding:10px;margin-bottom:20px;">
      <el-button type="primary" @click="previousStep">
        <el-icon>
          <back />
        </el-icon>
        Previous
      </el-button>
      <el-button type="primary" @click="printReceipt">
        <el-icon>
          <printer />
        </el-icon>
        Print
      </el-button>
    </div>
    <div id="printArea" class="printArea">
      <div class="header">
        <div class="leftPart">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td class="companyName">{{ form.companyName }}</td>
            </tr>
            <tr>
              <td>{{ form.detailedAddress }}</td>
            </tr>
            <tr>
              <td>{{ form.suburb }}, {{ form.postcode }}</td>
            </tr>
            <tr>
              <td>Phone: {{ form.phone }}</td>
            </tr>
            <tr>
              <td>ABN: {{ form.abn }}</td>
            </tr>
            <tr>
              <td>Website: {{ form.website }}</td>
            </tr>
          </table>
        </div>
        <div class="rightPart">
          <div class="templateAndLog">
            <div class="templateName">{{ form.templateName }}</div>
            <div class="logo"><img src="../../assets/img/je_logo.png" /></div>
          </div>
          <div class="billToDate">
            DATE
            <span class="date">{{
              this.$formatter.formatDate(form.billDate)
            }}</span>
          </div>
        </div>
      </div>
      <div class="billTo">
        <div class="title" style="background-color: #3b4e87;">BILL TO</div>
        <div class="name">{{ form.billTo }}</div>
      </div>
      <div class="items">
        <table border="0" cellpadding="0" cellspacing="0">
          <tr>
            <th style="width:50%;background-color: #3b4e87;">DESCRIPTION</th>
            <th style="width:30%;background-color: #3b4e87;">
              Subtotal (Incl.GST)
            </th>
            <th style="width:20%;background-color: #3b4e87;">GST</th>
          </tr>
          <tr
            v-for="(item, index) in form.items"
            :key="index"
            :class="index % 2 == 0 ? '' : 'odd'"
          >
            <td>
              {{ item.title }}
              {{ item.term ? `, ${item.term}` : "" }}
              {{ item.comment ? `, ${item.comment}` : "" }}
            </td>
            <td>{{ this.$formatter.formatDecimal(item.total) }}</td>
            <td>{{ this.$formatter.formatDecimal(item.gst) }}</td>
          </tr>
          <tr
            v-for="n in 16 - form.items.length"
            :key="n"
            :class="(n + 1 + form.items.length) % 2 == 0 ? '' : 'odd'"
          >
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>

      <div class="summary">
        <div class="leftPart">
          <div class="title" style="background: #3b4e87;">OTHER COMMENTS</div>
          <ul>
            <li v-for="(item, index) in form.comments" :key="index">
              {{ index + 1 }}. {{ item }}
            </li>
          </ul>
        </div>
        <div class="rightPart">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td>Total Subtotal</td>
              <td class="value">
                ${{ this.$formatter.formatDecimal(form.netTotal) }}
              </td>
            </tr>
            <tr>
              <td class="discount">Discount</td>
              <td class="discount value">
                ${{ this.$formatter.formatDecimal(form.discountAmount) }}
              </td>
            </tr>
            <tr>
              <td class="total">TOTAL</td>
              <td
                class="total value"
                style="background-color:#C6EFCE;color:#006100;"
              >
                ${{ this.$formatter.formatDecimal(form.total) }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="footer">
        <div class="container">
          <div class="contactDetail">{{ form.contact }}</div>
          <div class="compliment">{{ form.compliment }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  name: "buildReceiptTemplate",
  data() {
    return {
      controllerUrl: "/receipt",
      step: 1,
      form: {
        receiptId: "",
        studentId: "",
        term: "",
        gst: 10,
        discount: 0,
        discountItems: [],
        promotionType: "Normal",
        operator: "",
        companyLogo: "../../assets/img/je_logo.png",
        templateName: "RECEIPT",
        companyName: "JE Maths Education",
        detailedAddress: "Suite 25/1 Railway Parade",
        suburb: "Burwood",
        postCode: "2134",
        phone: "0422777073",
        abn: "45169016274",
        website: "http://www.jeeducation.com.au",
        billDate: new Date(),
        billTo: "",
        items: [],
        description: "",
        note: "",
        netTotal: 0, // without gst and discount amount
        discountAmount: 0,
        gstTotal: 0,
        total: 0, // include gst + discount amount
        comments: ["No Refund! No Credit! "],
        paymentMethods: ["By Cash.", "By Eftpos."],
        contact:
          "If you have any questions about this receipt, please contact [Jonathan Hu, Phone 0422777073, jemathseducation@gmail.com]",
        compliment: "Thank You For Your Business!",
      },
    };
  },
  watch: {
    "$route.query"(newValue) {
      console.log("this.$route.query", newValue);
      this.initAndLoadData(newValue);
    },
  },
  created() {
    this.initAndLoadData(this.$route.query);
  },
  methods: {
    initAndLoadData(query) {
      let receiptId = query.receiptId;
      console.debug("ReceiptTemplate.initAndLoadData", receiptId);

      if (receiptId && receiptId.length == 22) {
        this.form.receiptId = receiptId;
        this.loadData();
      }
    },
    loadData() {
      getData(`${this.controllerUrl}/${this.form.receiptId}`, null)
        .then((res) => {
          if (res.result && res.code === "200") {
            for (let pro in res.result) {
              this.form[pro] = res.result[pro];
            }
          } else {
            this.$message.error(
              "Fetch student data failed, error message: " + res.message
            );
          }
        })
        .catch(() => {});
    },
    calculateItemTotal(item) {
      item.total = item.unitPrice * item.quantity;
      return item.total;
    },
    calculateItemGST(item) {
      item.gstAmount = item.unitPrice * item.quantity * (0.01 * item.gst);
      return item.gstAmount;
    },
    calculateNetTotal() {
      let netTotal = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        netTotal += item.unitPrice * item.quantity;
      }
      this.form.netTotal = netTotal;
      return this.form.netTotal;
    },
    calculateDiscountAmount() {
      let discountAmount = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        discountAmount =
          discountAmount +
          item.unitPrice * item.quantity * (0.01 * item.discount) +
          item.unitPrice *
            item.quantity *
            (1 - 0.01 * item.discount) *
            (0.01 * item.successiveDiscount);
      }
      this.form.discountAmount = discountAmount;
      return this.form.discountAmount;
    },
    calculateTotal() {
      let total = 0;
      let gstTotal = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        total +=
          item.unitPrice *
          item.quantity *
          (1 - 0.01 * item.discount) *
          (1 - 0.01 * item.successiveDiscount);
        gstTotal += item.unitPrice * item.quantity * (0.01 * this.form.gst);
      }
      this.form.total = Math.round(total);
      this.form.gstTotal = gstTotal;
      return this.form.total;
    },
    printPreview() {
      if (this.validateData()) {
        this.step = 2;
      } else {
        this.$message.error("Validation failed, please correct!");
        return false;
      }
    },
    validateData() {
      return true;
    },
    previousStep() {
      this.step = 1;
    },
    printReceipt() {
      const prtHtml = document.getElementById("printArea").outerHTML;
      let title = `${this.form.billTo}-${
        this.form.term
      }-${this.$formatter.formatDate(this.form.billDate, "yyyyMMdd")}`;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <title>${title}</title>
<style>
@media print {
  body {
    margin:0;
    -webkit-print-color-adjust: exact;
  }
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.printArea {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  font-size: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.printArea .header {
  width: 100%;
  background-color: #fff;
}
.printArea .header .leftPart {
  float: left;
}
.printArea .header .leftPart .companyName {
  color: #2c3a65;
  font-size: 28px;
  line-height: 47px;
}
.printArea .header .rightPart {
  float: right;
}
.printArea .header .rightPart .templateName {
  float: left;
  color: #7a8dc5;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
}
.printArea .header .rightPart .logo {
  float: left;
}
.printArea .header .rightPart .logo img {
  width: 60px;
  height: 58px;
  margin-left: 10px;
}
.printArea .header .rightPart .billToDate {
  margin-top: 20px;
  float: right;
}
.printArea .header .rightPart .billToDate .date {
  color: #000;
  text-align: center;
  border: 0.5pt solid #a6a6a6;
  padding: 2px 20px;
}
.printArea .billTo {
  width: 100%;
  margin-top: 40px;
}
.printArea .billTo .title {
  color: white;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  background: #3b4e87;
  padding-left: 10px;
  width: 35%;
}
.printArea .billTo .name {
  padding-left: 10px;
}

.printArea .items {
  margin-top: 60px;
}
.printArea .items table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: 0.5pt solid #3b4e87;
  border-right-width: 0;
}

.printArea .items table tr {
  line-height: 20px;
  height: 20px;
}
.printArea .items table tr th {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  text-align: center;
  background: #3b4e87;
  border-right: 0.5pt solid #3b4e87;
}
.printArea .items table tr td {
  text-align: center;
  border-right: 0.5pt solid #3b4e87;
  font-weight: 400;
}
.printArea .items table tr.odd {
  background: #f2f2f2;
}
.printArea .summary {
  margin-top: 40px;
  width: 100%;
}
.printArea .summary .leftPart {
  float: left;
  width: 45%;
  height: 60px;
  border-collapse: collapse;
  table-layout: fixed;
  border: 0.5pt solid #3b4e87;
}
.printArea .summary .leftPart .title {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  padding-left: 10px;
  background: #3b4e87;
}
.printArea .summary .leftPart ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .summary .rightPart {
  float: right;
  width: 40%;
  border-top: 0.5pt solid #3b4e87;
  height: 148px;
}
.printArea .summary .rightPart table {
  width: 100%;
}
.printArea .summary .rightPart table tr td {
  width: 50%;
  font-size: 12pt;
  font-weight: 400;
}
.printArea .summary .rightPart table tr td.value {
  text-align: right;
}
.printArea .summary .rightPart table tr td.discount {
  font-size: 18px;
  font-weight: 700;
}
.printArea .summary .rightPart table tr td.total {
  font-size: 20px;
  font-weight: 700;
}
.printArea .summary .rightPart .paymentMethods {
  width: 100%;
  margin-top: 20px;
  height: 60px;
  border: 0.5pt solid #3b4e87;
  display: none;
}
.printArea .summary .rightPart .paymentMethods .title {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  padding-left: 10px;
  text-align: center;
  background: #3b4e87;
}
.printArea .summary .rightPart .paymentMethods ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .footer {
  margin-top: 200px;
  width: 100%;
}
.printArea .footer .container {
  width: 45%;
  padding: 0;
  margin: 0 auto;
  border-width: 0;
  text-align: center;
}
.printArea .footer .contactDetail {
  width: 100%;
}
.printArea .footer .compliment {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}
.printArea .footer::after,
.printArea .summary::after,
.printArea .header::after,
.printArea .header .rightPart .templateAndLog::after {
  content: "";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  overflow: hidden;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .printArea {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
</style>
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
    },
  },
};
</script>

<style slot-scope>
.receiptTemplate {
  margin-bottom: 30px;
}
.receiptTemplate .studentInput {
  float: left;
  width: 80%;
}
.receiptTemplate .searchStudentBtn {
  float: right;
  width: 20%;
}
.receiptTemplate .studentInfoPanel {
  margin-top: 10px;
  padding: 20px;
  background-color: #d3d3d3;
}
.courseTemplate {
  margin-top: 20px;
}
.courseTemplate .courseSelect {
  margin-top: 20px;
  width: 30%;
}
.courseTemplate h2 {
  font-size: 16px;
  font-weight: 700;
}
.courseTemplate .courseListTable {
  margin-top: 10px;
}
.courseTemplate .courseListTable .courseNameInput {
  float: left;
  width: 30%;
}
.courseTemplate .courseListTable .termSelect {
  float: left;
  width: 30%;
}
.courseTemplate .courseListTable .commentInput {
  float: right;
  width: 40%;
}
</style>
<style id="printCSS">
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.printArea {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  font-size: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.printArea .header {
  width: 100%;
  background-color: #fff;
}
.printArea .header .leftPart {
  float: left;
}
.printArea .header .leftPart .companyName {
  color: #2c3a65;
  font-size: 28px;
  line-height: 47px;
}
.printArea .header .rightPart {
  float: right;
}
.printArea .header .rightPart .templateName {
  float: left;
  color: #7a8dc5;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
}
.printArea .header .rightPart .logo {
  float: left;
}
.printArea .header .rightPart .logo img {
  width: 60px;
  height: 58px;
  margin-left: 10px;
}
.printArea .header .rightPart .billToDate {
  margin-top: 20px;
  float: right;
}
.printArea .header .rightPart .billToDate .date {
  color: #000;
  text-align: center;
  border: 0.5pt solid #a6a6a6;
  padding: 2px 20px;
}
.printArea .billTo {
  width: 100%;
  margin-top: 40px;
}
.printArea .billTo .title {
  color: white;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  background: #3b4e87;
  padding-left: 10px;
  width: 35%;
}
.printArea .billTo .name {
  padding-left: 10px;
}

.printArea .items {
  margin-top: 60px;
}
.printArea .items table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: 0.5pt solid #3b4e87;
  border-right-width: 0;
}

.printArea .items table tr {
  line-height: 20px;
  height: 20px;
}
.printArea .items table tr th {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  text-align: center;
  background: #3b4e87;
  border-right: 0.5pt solid #3b4e87;
}
.printArea .items table tr td {
  text-align: center;
  border-right: 0.5pt solid #3b4e87;
  font-weight: 400;
}
.printArea .items table tr.odd {
  background: #f2f2f2;
}
.printArea .summary {
  margin-top: 40px;
  width: 100%;
}
.printArea .summary .leftPart {
  float: left;
  width: 45%;
  height: 60px;
  border-collapse: collapse;
  table-layout: fixed;
  border: 0.5pt solid #3b4e87;
}
.printArea .summary .leftPart .title {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  padding-left: 10px;
  background: #3b4e87;
}
.printArea .summary .leftPart ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .summary .rightPart {
  float: right;
  width: 40%;
  border-top: 0.5pt solid #3b4e87;
  height: 148px;
}
.printArea .summary .rightPart table {
  width: 100%;
}
.printArea .summary .rightPart table tr td {
  width: 50%;
  font-size: 12pt;
  font-weight: 400;
}
.printArea .summary .rightPart table tr td.value {
  text-align: right;
}
.printArea .summary .rightPart table tr td.discount {
  font-size: 18px;
  font-weight: 700;
}
.printArea .summary .rightPart table tr td.total {
  font-size: 20px;
  font-weight: 700;
}
.printArea .summary .rightPart .paymentMethods {
  width: 100%;
  margin-top: 20px;
  height: 60px;
  border: 0.5pt solid #3b4e87;
  display: none;
}
.printArea .summary .rightPart .paymentMethods .title {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  padding-left: 10px;
  text-align: center;
  background: #3b4e87;
}
.printArea .summary .rightPart .paymentMethods ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .footer {
  margin-top: 200px;
  width: 100%;
}
.printArea .footer .container {
  width: 45%;
  padding: 0;
  margin: 0 auto;
  border-width: 0;
  text-align: center;
}
.printArea .footer .contactDetail {
  width: 100%;
}
.printArea .footer .compliment {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}
.printArea .footer::after,
.printArea .summary::after,
.printArea .header::after,
.printArea .header .rightPart .templateAndLog::after {
  content: "";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  overflow: hidden;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .printArea {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
</style>
